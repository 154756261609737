import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"

const ProductDevelopment = () => (
  <Layout>
    <SEO title="Product Development" />
    <HeroInner
      title="Product Development"
      imageAlt="Product Development"
      imagePath="services/tailored-services/product-development/hero.jpg"
    />
    <ArticleFullWidth
      descriptions={[
        `You can’t have a retail/E-commerce business without a good product to sell, which is why companies invest enormous resources into a thoughtful product development strategy. The most successful entrepreneur understands the importance of developing new products and improving their existing merchandise. Continuously finding ways to innovate will keep your business competitive.`,
        `Every great product starts with an idea. It happens when you and your team come up with a concept that would change the world—or at least, add value to other people’s lives.`,
        `At Blend-Divine we understand these business drivers and provide product solutions to our clients. Product development covers the complete process of bringing a new product to market. From ideation to execution, our engineers and designers work in tandem to understand your product at its core. With technical and methodological expertise, our team minimizes the costs of production and assembly, manufacturing your product to co-created specifications with cost, reliability, and user experience at top of mind. Blend-Divine Team wants to spend time understanding what the product vision is, as well as versing themselves in your business problems we’re solving, the big opportunities, and the market landscape. With this understanding in place, we launch into design to visualize our shared ideas for your product, translating requirements into prototypes. We also have a collaboration with different vendors all across the globe which help you source the products at competitive rates.`,
      ]}
    />
  </Layout>
)

export default ProductDevelopment
